<template>
  <header>
    <div class="header-container">
      <div class="content-logo">
          <img src="../../assets/img/logo.png" alt="">
      </div>
      <div class="content-nav">
        <ul>
          <li>
            <router-link  to="/home" style="cursor: pointer">메인</router-link>
          </li>
          <li>
            <router-link to= '/gameCenter' style="cursor: pointer">게임센터</router-link>
          </li>
          <li>
            <router-link to='/about' style="cursor: pointer" >회사 정보</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>              
<script>
export default {
  
}
</script>

<style>
  header {
		position: relative;
		width: 100%;
    z-index: 999;
	}
	.header-container {
    position: relative;
    width: 100%;
    /* height: 100px; */
	}
	.content-logo {
    position: absolute;
    left: 1.0667rem;
    top: 29px;
		width: 183px;
		height: 42px;
		cursor: pointer;
	}
	.content-logo img {
    display: block;
    width: 100%;
		height: 100%;
	}
	.content-nav {
    display: flex;
		justify-content: center;
    align-items: center;
		width: 6rem;
    margin: 0 auto;
    font-family: 'Microsoft YaHei',微软雅黑;
	}
	.content-nav ul {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	.content-nav ul li {
     line-height: 100px;
      font-size: 18px;
      cursor: pointer;
	}
	.content-nav ul li a{
    color: #8d8c8d;
    text-decoration: none;
  }
  .router-link-active {
    position: relative;
    color: #fff!important;
  }
  .router-link-active::after {
    content: "";
    position: absolute;
    bottom: -12px !important;
    left: 50%;
    width: 80px;
    height: 2px;
    background-color: #f08200;
    border-radius: 5px !important;
    transform: translateX(-50%);
  }
</style>