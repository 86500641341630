<template>
  <div class="home">
    <Header></Header>
    <div class="banner-swiper">
        <div class="btn" @click="goNav">알아보기 </div>
         <el-carousel :interval="4000" type="card" height="9rem">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
             <h1 class="gameName">{{ item.title }}</h1>
            <div class="swiper-content">
               <img src="../assets/img/shouye/border.png" class="swiper-border" alt="">
               <img :src="item.img" class="swiper-img" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
    </div>
    <div class="home-footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '../components/common/Header.vue'
import Footer from '../components/common/Footer.vue'
import { banner } from '../utils/banner'
export default {
  name:'Home',
  components: {
    Header,
    Footer,
  },
  data() {
   return {
        bannerList:[],
        bannerParams:{
        language: 2,
        platform: 1
      }
   }
  },
  created() {
    banner(this.bannerParams).then(res => {
      if(res.data.status == 2000) {
        this.bannerList = res.data.data
      }
    })
  },
  methods: {
    goNav() {
      this.$router.push({
        path: '/gameCenter'
      })
    }
  }
}
</script>

<style lang="scss" scope>
  .home {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    background: #333843;
  }
  @media screen and (max-width: 1632px){
  .home {
       height: 100vh!important;
     }
  // .btn { 
  //   top: 6.3rem!important;
  // }
.el-carousel__container {
  height: 9.3rem!important;
}
    .banner-swiper {
      margin-top: -1.8rem!important;
    }
  }
@media only screen and (min-width: 1633px) and (max-width: 1728px){
  .btn { 
    top: 6.3rem!important;
  }
}
@media only screen and (min-width: 1729px) and (max-width: 1824px){

}
@media only screen and (min-width: 1633px) and (max-width: 1920px){
    .el-carousel__container {
  height: 8.8rem!important;
}
.el-carousel__indicators--outside {
  bottom: 0!important;
} 

}
  @media screen and (min-width: 1920px){
     .home {
       height: 100vh!important;
     }
  }
.header-container {
  width: 100%;
  background: url('../assets/img/shouye/showdow.png') no-repeat;
  background-size: 100% 100%;
}
 .banner-swiper {
   flex: 1;
   width: 100%;
   height: 8rem;
   margin-top: -1.3rem;
   background: #333843;
 }
 .gameName {
   position: absolute;
   top: 5.4rem;
   left: 42%;
   z-index: 999;
   font-family: 'NotoSansKR-Medium';
   font-size: .6rem;
   color: #959698;
   text-align: center;
   line-height: .7rem;
   transform: translateX(-50%);
 }
 .btn {
   position: absolute;
   top: 6.1rem;
   left: 47.9%;
   padding: 0 .5rem;
   z-index: 999;
   border: 2px solid #fff;
   font-family: 'NotoSansKR-Medium';
   font-size: .32rem;
   color: #fff;
   line-height: .43rem;
   transform: translateX(-50%);
   cursor: pointer;
 }
 .el-carousel--horizontal {
   overflow: hidden;
 }
.el-carousel__mask {
  background: transparent!important;
}
.el-carousel__arrow {
  display: none!important;;
}
.el-carousel__indicators--outside {
  position: absolute!important;
}
.el-carousel__button {
  width: .2rem!important;
  height: .2rem!important;
  border-radius: 50%;
}
.el-carousel__item--card.is-active .gameName {
  color: #fff;
}
.el-carousel__indicator.is-active button {
  width: .8rem!important;
  border-radius: 6px!important;
  background: #f08200;
}
.el-carousel__indicators--outside {
  // bottom: 0!important;
  transform: translateX(-50%)!important;
}
.el-carousel__indicators--outside button {
  background: #abacb0;
  opacity:1!important;
}
// .el-carousel--horizontal {
//   overflow: hidden;
// }
.el-carousel__indicator--horizontal {
  padding: .4rem .2667rem!important;
}
.el-carousel__item {
  top: -6%!important;
}
.el-carousel__item--card {
  width: 55%!important;
}
.el-carousel__item.is-active {
  margin-top: .8rem;
}
.swiper-content {
  position: relative;
}
.el-carousel__item img {
    -webkit-filter: grayscale(90%);

    /* Chrome, Safari, Opera */

    filter: grayscale(90%);
    width: 97%;
    // margin-top: .2rem;
}
.swiper-border {
  display: none;
  position: relative;
  // top: .2rem;
}

.el-carousel__item--card.is-active img{
  -webkit-filter: grayscale(0);

    /* Chrome, Safari, Opera */

    filter: grayscale(0);
}
.el-carousel__item--card.is-active .swiper-border {

  display: block;
  // z-index: 99;
}
.swiper-img {
  position: absolute;
  top: 3px;
  left: 4.7%;
  width: 89.3%!important;
  // width: 9.8rem!important;
}
.home-footer {
  width: 100%;
  background: url('../assets/img/shouye/showdow2.png') no-repeat;
  background-size: 100% 100%;
    .copy-top {
      color: #959698;
      z-index: 999;
    }
}
</style>