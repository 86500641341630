import request from './request'

export function games(params) {
  return request({
    url: '/front/language/games',
    params
  })
}

export function banner(params) {
  return request({
    url: '/front/home/language/banner',
    params
  })
}