<template>
  <div class="foot">
      <ul class="copy-top">
        <li @click="goUs"><a href="javascript:;">연락처</a></li>
        <li>｜</li>
        <li ><a href="https://www.iclockwork.co.kr/privacy.html" target="_blank">개인정보처리방침</a></li>
      </ul>
      <div class="copy-bottom">
        <span class="">©iClockwork Inc. All right reserved.</span>
      </div>
  </div>
</template>
<script>
export default {
  methods: {
    goUs() {
      this.$router.push({
        path: '/about?from=footer',
      })
    }
  }
}
</script>
<style lang="scss">
    .foot {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-width: 1200px;
      height: 150px;
      font-family: 'Microsoft YaHei',微软雅黑;
    }
    .copy-top {
      font-size: 14px;
      margin: 10px;
      display: flex;
    }
    .copy-top li {
      margin: 0 10px;
      color: #b9b9b9;
    }
    .copy-top li a {
      color: #b9b9b9 !important;
      text-decoration: none;
    }
    .copy-bottom {
      color:#b9b9b9;
      font-size: 12px;
    }
</style>